import { client } from 'utils/client'
import { useQuery } from 'react-query'

const fetchLocale = async () => {
  return client(`/tenants/current`).then(data => data?.data)
}

const useLocale = (config) => {
    return useQuery('locale', () => fetchLocale(), config);
  };

export { fetchLocale, useLocale }
