import { useQuery } from 'react-query'
import { client } from 'utils/client'

const fetchRefreshCas = async ({query = {}}) => {
  return client(`/refresh_cas_ticket`, { params: query }).then(data => data?.data)
}

const useRefreshCas = ({query, options}) => {
  return useQuery('refresh-cas', () => fetchRefreshCas({query}), {
      ...options
  })
}

export { useRefreshCas, fetchRefreshCas }
